<template>
  <v-theme-provider dark>
    <v-dialog v-model="needToLogInDialogVisibleProp" width="100%" persistent background="white" transition="dialog-bottom-transition">
      <v-card color="white" align="center">
        <v-card-title>Log in to continue…</v-card-title>
        <v-spacer />
        <v-btn color="accent" class="font-weight-bold" min-width="96" @click="loginAction()"><v-icon left color="white">mdi-login</v-icon>Member Login</v-btn>
        <v-spacer /><br />
      </v-card>
    </v-dialog>
  </v-theme-provider>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { AuthServiceConstants, AuthServiceStage } from '@/msal/AuthServiceInterface'
import { EnvX } from '@/environments/EnvX.ts'
import { PubSub } from '@/publishsubscribe/pub-sub'

export default {
  name: 'mustlogin',

  data: () => ({
    loggedStageProp: AuthServiceStage.Unknown
  }),

  mounted() {
    PubSub.subscribe(AuthServiceConstants.LOG_STATUS_CHANGED, this.loggedStatusChangedCallback)
    setTimeout(this.checkLoginStatus, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },
    needToLogInDialogVisibleProp() {
      return this.loggedStageProp === AuthServiceStage.LoggedOut
    }
  },

  methods: {
    checkLoginStatus() {
      try {
        this.$AuthService
          .idTokenAsync('mustLogin.checkLogin')
          .then(() => {
            this.loggedStageProp = AuthServiceStage.LoggedIn
          })
          .catch((error) => {
            if (AuthServiceConstants.LOGGED_OUT === error.message) {
              this.loggedStageProp = AuthServiceStage.LoggedOut
            } else {
              this.loggedStageProp = AuthServiceStage.Unknown
              EnvX.warn('🧨', error)
            }
          })
      } catch (exception) {
        this.loggedStageProp = AuthServiceStage.Unknown
        EnvX.error('💥', exception)
      }
    },

    loggedStatusChangedCallback(authServiceIsLoggedIn) {
      this.loggedStageProp = authServiceIsLoggedIn ? AuthServiceStage.LoggedIn : AuthServiceStage.LoggedOut
    },

    loginAction() {
      EnvX.log('🌵 Current route', this.$route.name)
      this.$router.push({
        name: 'login', // push route name so that we can add params
        params: {
          successRoute: this.$route.name,
          failureRoute: 'homeroute'
        }
      })
    }
  }
}
</script>
